import { ApolloClient } from 'apollo-boost'
import { LoginResponse } from 'scenes/Login/Login'

export async function setLoggedIn(
  loginResponse: LoginResponse | false,
  client: ApolloClient<object>
): Promise<boolean> {
  let token = null
  if (loginResponse && loginResponse.success && loginResponse.user) {
    token = loginResponse.user.token as string
    localStorage.setItem('token', token)
  } else {
    await client.clearStore()
    localStorage.removeItem('token')
  }

  localStorage.removeItem('session')

  client.writeData({
    data: {
      loggedIn: !!token
    }
  })

  return !!token
}
