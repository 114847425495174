import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ApolloProvider } from '@apollo/react-hooks'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-boost'
import ApolloClient from 'apollo-client'
import { persistCache } from 'apollo-cache-persist'

import { PersistentStorage, PersistedData } from 'apollo-cache-persist/types'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { BrowserRouter as Router } from 'react-router-dom'
import _ from 'lodash'
import { setLoggedIn } from './util/login'
import { createUploadLink } from 'apollo-upload-client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { split, ApolloLink } from '@apollo/client'
import { DocumentNode } from 'graphql'

const setupAndRender = async () => {
  const apolloServer = await getApolloServerUrl()
  const wsUrl = `${apolloServer
    .replace('http://', 'ws://')
    .replace('https://', 'wss://')}/graphql`
  const cache = new InMemoryCache()

  const getTokenHeaders = () => ({
    'access-token': localStorage.getItem('token') || '',
    'session-token': localStorage.getItem('session') || '',
    'app-code': 'client'
  })

  // Apply access-token to the headers of any graphql request
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...getTokenHeaders()
      }
    }
  })

  const uploadLink = createUploadLink({
    uri: apolloServer,
    headers: getTokenHeaders
  })

  const onUnauthorisedError = onError(({ graphQLErrors }) => {
    const unauthorisedError = _.find(graphQLErrors, (graphQLError) => {
      return graphQLError.message === '401: Unauthorized'
    })

    if (unauthorisedError) {
      setLoggedIn(false, client)
      window.location.href = `${window.location.origin}/login`
    }
  })

  const wsLink = new WebSocketLink({
    uri: wsUrl,
    options: {
      reconnect: true,
      connectionParams: getTokenHeaders
    }
  })

  const httpLink = authLink.concat(onUnauthorisedError).concat(uploadLink)

  const splitLink = split(
    (request: { query: DocumentNode }) => {
      const definition = getMainDefinition(request.query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    (httpLink as unknown) as ApolloLink
  )

  const client = new ApolloClient({
    cache: cache,
    resolvers: {},
    // @ts-ignore
    link: splitLink
  })

  await persistCache({
    cache,
    storage: window.sessionStorage as PersistentStorage<
      PersistedData<NormalizedCacheObject>
    >
  })
  ReactDOM.render(
    <ApolloProvider client={client}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>,
    document.getElementById('root')
  )
}

setupAndRender()

async function getApolloServerUrl(): Promise<string> {
  if (process.env.NODE_ENV !== 'production') {
    return `${window.location.protocol}//${window.location.hostname}:${4000}`
  }

  try {
    const clientServerGetConfigUrl = `${window.location.protocol}//${window.location.hostname}/app_config`
    const response = await fetch(clientServerGetConfigUrl)
    const config: { apolloServer: string } = await response.json()

    return config.apolloServer
  } catch (err) {
    console.error(err)
    throw err
  }
}
