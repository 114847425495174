import React from 'react'
import { SVGLogo } from '@pcs/react-components'

interface AppLogoProps {
  onClick?: () => void
  size?: number
}

const AppLogo = (props: AppLogoProps) => {
  return (
    <SVGLogo
      src="/logo.svg"
      {...props}
    />
  )
}

export default React.memo(AppLogo)
