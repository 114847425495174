import gql from 'graphql-tag'

export const GET_CURRENT_USER = gql`
  {
    currentUser {
      _id
      username
      firstname
      surname
      avatar {
        _id
        downloadUrl
      }
      organisation {
        _id
        name
      }
      apiKey
    }
  }
`

export const GET_USER_PREFERENCE_SCREENS = gql`
  {
    getUserPreferenceScreens {
      _id
      name
    }
  }
`

export const GET_PREFERENCES_FOR_SCREEN = gql`
  query GetPreferencesForScreen($screenId: ID!) {
    getPreferencesForScreen(screenId: $screenId) {
      _id
      value
      option {
        code
        name
        fieldType
        defaultValue
      }
    }
  }
`

export const GET_USER_PREFERENCE_FROM_CODE = gql`
  query GetUserPreferenceFromCode($code: String!) {
    getUserPreferenceFromCode(code: $code) {
      value
    }
  }
`

export const GET_USER = gql`
  query GetUser($userId: ID) {
    getUser(userId: $userId) {
      _id
      firstname
      surname
      avatar {
        _id
        downloadUrl
      }
      status {
        _id
        name
        color
      }
    }
  }
`

export const GET_USERS = gql`
  query GetUsers($team: String, $includeDeletedUsers: Boolean) {
    getUsers(team: $team, includeDeletedUsers: $includeDeletedUsers) {
      _id
      firstname
      surname
      username
      title
      email
      organisation {
        _id
        name
      }
      jobsCompletedToday
      avatar {
        _id
        downloadUrl
      }
      teams {
        name
      }
      status {
        _id
        name
        color
      }
      markedAsDeleted
      thirdPartyId
    }
  }
`

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    getAllUsers {
      _id
      firstname
      surname
      username
      title
      email
      organisation {
        _id
        name
      }
      jobsCompletedToday
      avatar {
        _id
        downloadUrl
      }
      teams {
        name
      }
      status {
        _id
        name
        color
      }
      markedAsDeleted
      thirdPartyId
    }
  }
`

export const GET_MAINTENANCE_USERS = gql`
  query GetMaintenanceUsers(
    $includeDeletedUsers: Boolean
    $pageInput: PageInput
    $search: String
  ) {
    getMaintenanceUsers(
      includeDeletedUsers: $includeDeletedUsers
      pageInput: $pageInput
      search: $search
    ) {
      rows {
        _id
        firstname
        surname
        username
        title
        email
        organisation {
          _id
          name
        }
        avatar {
          _id
          downloadUrl
        }
        status {
          _id
          name
          color
        }
        markedAsDeleted
        thirdPartyId
      }
      page
      pageSize
      total
      totalPages
    }
  }
`

export const GET_USERS_WITH_A_TEAM = gql`
  query GetUsersWithATeam($input: UserQueryInput) {
    getUsersWithATeam(input: $input) {
      _id
      firstname
      surname
      jobsCompletedToday
      assignedJobsCount
      avatar {
        _id
        downloadUrl
      }
      teams {
        name
      }
      status {
        _id
        name
        color
      }
    }
  }
`

export const GET_CURRENT_USER_COLUMN_PREFERENCES = gql`
  {
    getCurrentUserProfile {
      _id
      appearanceBrowserColumns {
        name
        position
      }
      jobBrowserColumns {
        name
        position
      }
    }
  }
`

export const GET_AVAILABLE_ORGANISATIONS_FOR_USER = gql`
  query GetAvailableOrganisationsForUser($userId: String) {
    getAvailableOrganisationsForUser(userId: $userId) {
      _id
      name
    }
  }
`

export const GET_LOGGED_IN = gql`
  {
    loggedIn @client
  }
`
