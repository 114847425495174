import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { GET_LOGGED_IN } from 'queries/user'

interface AuthenticatedRouteProps {
  component?: React.ComponentType<any>
  path?: string
  exact?: boolean
  render?: () => JSX.Element
}

const AuthenticatedRoute = ({
  component: Component,
  render,
  ...routeProps
}: AuthenticatedRouteProps) => {
  const { data } = useQuery(GET_LOGGED_IN)

  return (
    <Route
      {...routeProps}
      render={(otherProps) => {
        if (!data || !data.loggedIn) {
          return (
            <Redirect
              to={{
                pathname: '/login'
              }}
            />
          )
        }

        if (render) {
          return render()
        }

        if (!Component) {
          return null
        }

        return <Component {...otherProps} />
      }}
    />
  )
}

export default AuthenticatedRoute
