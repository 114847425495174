import { AppThemes, AppTheme } from '../definitions/theme'
import themes from '../themes'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const GET_THEME = gql`
  {
    appTheme @client
  }
`

export default function useAppTheme(): AppTheme {
  const { data } = useQuery(GET_THEME)
  const themeName: keyof AppThemes = data?.appTheme || 'defaultTheme'

  return themes[themeName]
}
