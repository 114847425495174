import React, { Suspense, lazy } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import AuthenticatedRoute from './components/Routes/AuthenticatedRoute/AuthenticatedRoute'
import { createGlobalStyle, ThemeProvider } from './style-helpers/styled'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { AppInfoProvider } from '@pcs/react-components'

import '@pcs/react-components/build/bundle.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import useAppTheme from './hooks/useAppTheme'
import AppLogo from 'components/AppLogo'

const GlobalStyle = createGlobalStyle`
  body {
    height: 100%;
    margin: 0;
    ${(props) => props.theme?.backgrounds.navigation}
  }

  #root {
    height: 100%;
    margin: 0;
  }

  .transition-group {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
`

const Login = lazy(() => import('./scenes/Login'))
const ForgotPassword = lazy(() => import('./scenes/ForgotPassword'))
const ResetPassword = lazy(() => import('./scenes/ResetPassword'))
const SetInitialPassword = lazy(() => import('./scenes/SetInitialPassword'))
const ProofJob = lazy(() => import('./scenes/ProofJob'))
const Main = lazy(() => import('./scenes/Main'))

const App = () => {
  const location = useLocation()
  const transitionKey = location.pathname === '/login' ? 'login' : 'main'

  const appInfo = {
    appName: 'Knowledge Pulse',
    appLogo: (large: boolean) => <AppLogo size={large ? 400 : 150} />
  }

  return (
    <AppInfoProvider appInfo={appInfo}>
      <ThemeProvider theme={useAppTheme()}>
        <GlobalStyle />
        <TransitionGroup className="transition-group">
          <CSSTransition timeout={400} classNames="page" key={transitionKey}>
            <Suspense fallback={null}>
              <Switch location={location}>
                <Route path="/login" exact component={Login} />
                <Route path="/forgot-password" exact component={ForgotPassword} />
                <Route
                  path="/reset-password/:session"
                  exact
                  component={ResetPassword}
                />
                <Route
                  path="/set-initial-password/:session"
                  exact
                  component={SetInitialPassword}
                />
                <Route path="/proof-job/:session" exact component={ProofJob} />
                <AuthenticatedRoute path="/" component={Main} />
              </Switch>
            </Suspense>
          </CSSTransition>
        </TransitionGroup>
      </ThemeProvider>
    </AppInfoProvider>
  )
}

export default App
