import { css } from 'styled-components'
import { AppTheme } from '../definitions/theme'

const MAIN_TEXT_COLOR = '#767676'
const BORDER_COLOR = 'rgba(34,36,38,.1)'

const defaultTheme: AppTheme = {
  name: 'Light Theme',
  size: {
    sideBarWidth: 220,
    collapsedSideBarWidth: 55
  },
  dragOverOutline: css`
    border: 3px dotted ${BORDER_COLOR};
    border-radius: 3px;
  `,
  border: css`
    border: 1px solid ${BORDER_COLOR};
    border-radius: 3px;
  `,
  colors: {
    base: '#fff',
    primary: '#5dbcd2',
    primaryHover: '#bee4ed',
    alert: '#c0392b',
    border: BORDER_COLOR,
    positive: '#2ecc71',
    negative: '#e74c3c',
    stopped: '#c0392b',
    text: {
      onFullScreenScene: '#fff',
      onPrimary: '#fff',
      onBase: MAIN_TEXT_COLOR,
      onNavigation: '#fff'
    },
    sideBar: {
      menuItemHover: '#69768C'
    },
    button: {
      standard: '#e0e1e2',
      standardText: 'rgba(0,0,0,.6)',
      standardHover: '#cacbcd'
    }
  },
  backgrounds: {
    offset: css`
      background-color: rgb(249, 250, 251);
    `,
    offsetEmphasis: css`
      background-color: rgb(239, 242, 247);
    `,
    page: css`
      background-color: #4f5c72;
    `,
    navigation: css`
      background-color: #4f5c72;
    `,
    fullScreenScene: css`
      background-image: linear-gradient(
        45deg,
        rgb(48, 38, 98),
        rgb(81, 240, 237)
      );
    `,
    main: css`
      background: linear-gradient(
          224deg,
          transparent 0%,
          transparent 30%,
          rgba(249, 249, 249, 0.02) 30%,
          rgba(249, 249, 249, 0.02) 36%,
          transparent 36%,
          transparent 100%
        ),
        linear-gradient(
          345deg,
          transparent 0%,
          transparent 53%,
          rgba(140, 140, 140, 0.02) 53%,
          rgba(140, 140, 140, 0.02) 63%,
          transparent 63%,
          transparent 100%
        ),
        linear-gradient(
          284deg,
          transparent 0%,
          transparent 28%,
          rgba(1, 1, 1, 0.02) 28%,
          rgba(1, 1, 1, 0.02) 38%,
          transparent 38%,
          transparent 100%
        ),
        linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
    `
  },
  pushNotification: {
    text: 'black',
    background: 'white',
    border: 'rgba(0,0,0,0.65)'
  }
}

export default defaultTheme
